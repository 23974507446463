// ======================
// Tips Responsive slider
// ======================

import Swiper from 'swiper';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const sliders = document.querySelectorAll('.Tips-responsiveSlider');

  for (const slider of sliders) {

    const sliderContainer = slider.querySelector('.Slider-container');
    const options = {
      slidesPerView: 'auto',
      spaceBetween: 30,
      watchOverflow: true
    };

    const breakpoint = window.matchMedia('(min-width: 1100px)');
    let newResponsiveSlider;

    const breakpointChecker = function () {
      if (breakpoint.matches === true) {
        if (newResponsiveSlider !== undefined) newResponsiveSlider.destroy(true, true);
        return;
      } else if (breakpoint.matches === false) {
        return enableSwiper();
      }
    };

    const enableSwiper = function () {
      newResponsiveSlider = new Swiper(sliderContainer, options)
    };

    breakpointChecker();
    breakpoint.onchange = breakpointChecker();
  }
}