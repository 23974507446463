
if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    // remove subdomain of current site's url and setup regex
    let internal = location.host.replace("www.", "");
    internal = new RegExp(internal, "i");

    let a = document.getElementsByTagName('a'); // then, grab every link on the page
    for (let i = 0; i < a.length; i++) {
        let href = a[i].host; // set the host of each link
        if (a[i].href != '' && a[i].target == '' && !internal.test(href)) { // make sure the href doesn't contain current site's host
            a[i].setAttribute('target', '_blank'); // if it doesn't, set attributes
        }
    }
}