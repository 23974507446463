
let klaro = require('./klaro.js');

var mutationObserver = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
        if (mutation.addedNodes[0] && (mutation.addedNodes[0].localName === "script" || mutation.addedNodes[0].localName === "iframe")) {
            // console.log(mutation.addedNodes[0].dataset.name);
            let categoryName = mutation.addedNodes[0].dataset.name;
            let webpack = klaro.getManager();
            if (typeof(categoryName) !== 'undefined' && categoryName === 'lazy-stats') {
                if(getCookie('klaro') !== "") {
                webpack.updateConsent(categoryName, !klaro.getManager().getConsent(categoryName))
                webpack.saveAndApplyConsents();
                webpack.updateConsent(categoryName, !klaro.getManager().getConsent(categoryName))
                webpack.saveAndApplyConsents();
                }
            }
        }
    });
});


// Starts listening for changes in the root HTML element of the page.
mutationObserver.observe(document.documentElement, {
    childList: true,
    subtree: true,
});


function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
