// =============
// Menu Dropdown
// =============

import $ from 'jquery';
import { Dropdown, ResponsiveMenu } from './foundation';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const menu = $('[data-menu-dropdown]');
  if (menu.length === 0) {
    return;
  }

  const menuItem = $('.Menu-item'),
        menuFirstItem = $('.Menu-item--first');
  const menuOptions = {
    closeOnClick: true,
    closeOnClickInside: false,
  };

  const menuInit = new Dropdown(menu, menuOptions);

  // Open state
  menu.on('show.zf.dropdown', function() {
    menu.children().fadeOut().slideDown(400);
    menuFirstItem.addClass('is-active');

    if (window.matchMedia('(max-width: 700px)').matches) {
      $('body').addClass('Locked');
    }
  });

  // Close state
  menu.on('hide.zf.dropdown', function() {
    menu.children().fadeIn().slideUp(300);
    menuItem.removeClass('is-active').attr('data-is-click', 'false');
    menuItem.children('.Menu-level').removeClass('js-dropdown-active');

    if (window.matchMedia('(max-width: 700px)').matches) {
      $('body').removeClass('Locked');
    }
  });
}