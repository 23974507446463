// =============================
// Target blank detection script
// =============================

const linksGroup = document.querySelectorAll('a');

[].forEach.call(linksGroup, function(externalLink) {

  if (externalLink.getAttribute('target', '_blank')) {
    // Save title in a variable
    const linkTitle = externalLink.getAttribute('title');

    // Add an explicit mention
    externalLink.setAttribute('title', linkTitle + " (nouvelle fenêtre)");
  }
});