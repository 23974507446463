// ================
// Footer Accordion
// ================

import $ from 'jquery';
import { Accordion } from './foundation';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const accordion = $('[data-footer-accordion]');
  const accordionOptions = {
    allowAllClosed: true,
    multiExpand: true
  };

  if (Foundation.MediaQuery.get('medium') && Foundation.MediaQuery.upTo('medium')) {
    const accordionInit = new Accordion(accordion, accordionOptions);
  }
  $(window).on('changed.zf.mediaquery', function() {
    if (Foundation.MediaQuery.get('medium') && Foundation.MediaQuery.upTo('medium')) {
      const accordionInit = new Accordion(accordion, accordionOptions);
    } else {
      if (accordion.attr('data-accordion')) {
        accordion.foundation('_destroy');
      }
    }
  });
}

