// ==========
// Books list
// ==========

import imagesLoaded from 'imagesloaded';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function setMaxWidth(listItems) {
  for (const listItem of listItems) {
    if (!listItem.style.maxWidth) {
      let cover = {};

      if (listItem.classList.contains('BookItem')) {
        cover = listItem.querySelector('.BookItem-cover img:first-child');
        imagesLoaded(cover, function() {
          const coverWidth = cover.width;
          listItem.style.maxWidth = coverWidth + 'px';
        })
      }
      else if (listItem.classList.value === 'NewsItem') {
        cover = listItem;
        cover.style.width = 277 + 'px';
      }
      else if (listItem.classList.value === 'CirclesList-item') {
        cover = listItem.querySelector('.CirclesList-image');
        cover.style.width = 215 + 'px';
        cover.style.height = 215 + 'px';
      }
    }
  }
}


function init() {
  const lists = document.querySelectorAll('.BooksList');
  for (const list of lists) {
    setMaxWidth(list.querySelectorAll('.BookItem'));
    setMaxWidth(list.querySelectorAll('.NewsItem'));
    setMaxWidth(list.querySelectorAll('.CirclesList-item'));

    const observer = new MutationObserver(function(mutations) {
      setMaxWidth(list.querySelectorAll('.BookItem'));
      setMaxWidth(list.querySelectorAll('.NewsItem'));
      setMaxWidth(list.querySelectorAll('.CirclesList-item'));
    });

    // Apply max with on post loading added BookItem
    observer.observe(list, { attributes: false, childList: true, characterData: false, subtree: false });

    // Test
    // const listsLi = list.querySelectorAll('li');
    //
    // for (const listLi of listsLi) {
    //   if (!listLi.classList.contains('SearchResults-filter')) {
    //     console.log(listLi.clientWidth);
    //   }
    // }
    //
    //
    // var detectWrap = function(className) {
    //
    //   var wrappedItems = [];
    //   var prevItem = {};
    //   var currItem = {};
    //   var items = document.getElementsByClassName(className);
    //
    //   for (var i = 0; i < items.length; i++) {
    //     currItem = items[i].getBoundingClientRect();
    //     if (prevItem && prevItem.top < currItem.top) {
    //       wrappedItems.push(items[i]);
    //     }
    //     prevItem = currItem;
    //   };
    //
    //   return wrappedItems;
    //
    // }
    //
    // window.onload = function(event){
    //   var wrappedItems = detectWrap('item');
    //   for (var k = 0; k < wrappedItems.length; k++) {
    //     wrappedItems[k].className = "wrapped";
    //   }
    // };
  }
}

export { setMaxWidth }
