// ===========
// News slider
// ===========

import Swiper from 'swiper';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const sliders = document.querySelectorAll('.NewsSlider');

  for (const slider of sliders) {
    const sliderContainer = slider.querySelector('.Slider-container');
    const newSlider = new Swiper(sliderContainer, {
      slidesPerView: 'auto',
      spaceBetween: 30,
      watchOverflow: true,
      navigation: {
        prevEl: slider.querySelector('.Slider-buttonPrev'),
        nextEl: slider.querySelector('.Slider-buttonNext')
      },
      pagination: {
        el: slider.querySelector('.Slider-bullets'),
        type: 'bullets',
        bulletElement: 'li',
        bulletClass: 'Slider-bullet',
        bulletActiveClass: 'Slider-bullet--active',
        clickable: true
      }
    })
  }
}

