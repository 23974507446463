/**
 * Front needs to have a <div> around the <table> of the Table platform native custom_tag
 */

(function wrapTable() {
    if (document.readyState != 'loading'){
        init();
    } else {
        document.addEventListener('DOMContentLoaded', init);
    }

    function init() {
        var tables = window.document.querySelectorAll('.ezrichtext-field > table');
        if (tables.length === 0) {
            return;
        }
        let wrapperTagName = 'div';
        let wrapperClassName = 'tableoverlay';

        // Get the matching elements and wrap them
        Array.prototype.forEach.call(tables, function(el, i){
            let wrapper = window.document.createElement(wrapperTagName);
            wrapper.innerHTML = el.outerHTML;
            wrapper.classList.add(wrapperClassName);

            el.parentNode.insertBefore(wrapper, el);
            el.remove();
        });
    }

})();