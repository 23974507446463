// =======================
// Search Facets Thematics
// =======================

import Awesomplete from "awesomplete";
import $ from "jquery";

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const searchFacetsThematic = document.querySelector('#SearchFacetsThematicSearch');

  if (searchFacetsThematic != null) {
    let list = [];
    let $container = $('#SearchFacetsThematicSearch');

    $($container.data('list')).children().each(function () {
      let $this = $(this);
      list.push({label: $this.text(), value: $this.data('value')});
    });

    $container.removeAttr('data-list');

    const comboplete = new Awesomplete('#SearchFacetsThematicSearch', {
      minChars: 0,
      maxItems: 999,
      list: list,
      sort: false,
      replace: function (suggestion) {
        this.input.value = suggestion.label;
      }
    });

    Awesomplete.$('#SearchFacetsThematicSearchButton').addEventListener("click", function () {
      if (comboplete.ul.childNodes.length === 0) {
        comboplete.minChars = 0;
        comboplete.evaluate();
      } else if (comboplete.ul.hasAttribute('hidden')) {
        comboplete.open();
      } else {
        comboplete.close();
      }
    });

    Awesomplete.$('#SearchFacetsThematicSearch').addEventListener("awesomplete-selectcomplete", function (event) {
      window.location.href = event.text.value;
    });
  }
}

