// ============
// Ages Tooltip
// ============

import tippy, {inlinePositioning} from 'tippy.js';
import $ from "jquery";

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const template = document.getElementById('InspirationsAgesList');

  if (template !== null) {
    template.style.display = 'block';

    const instance = tippy('[data-tippy-content]', {
      content: template,
      interactive: true,
      inlinePositioning: true, // Trying to disable auto positioning
      plugins: [inlinePositioning],
      trigger: 'click',
      maxWidth: 'none',
      placement: 'bottom-start',
      animation: 'shift-away'
    });

    $(template).on('click', 'li', function () {
      instance[0].hide();
    });
  }
}


