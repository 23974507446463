// =========
// Book Flip
// =========

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const bookContainers = document.querySelectorAll('.BookFlip-container');

  for (const bookContainer of bookContainers) {
    const book = bookContainer.querySelector('.BookFlip'),
          button = bookContainer.nextElementSibling;

    // Flip on button click
    function clickFlip() {
      if (book.classList.contains('BookFlip--front')) {
        book.classList.remove('BookFlip--front');
        book.classList.add('BookFlip--back');
      } else if (book.classList.contains('BookFlip--back')) {
        book.classList.remove('BookFlip--back');
        book.classList.add('BookFlip--front');
      }
    }

    button.addEventListener('click', clickFlip);
  }
}

