// ===============
// Range component
// use NoUiSlider
// ===============

import noUiSlider from 'nouislider';
import $ from 'jquery';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const ranges = document.querySelectorAll('.SearchFacets-range');

  for (const range of ranges) {
    var start = 0;
    var end = 50;

    if (typeof range.dataset['start'] != 'undefined' ) {
      start = parseInt(range.dataset['start'], 10);
    }

    if (typeof range.dataset['end'] != 'undefined' ) {
      end = parseInt(range.dataset['end'], 10);
    }

    noUiSlider.create(range, {
      connect: true,
      start: [start, end],
      range: {
        'min': [0, 5],
        '15%': [5, 5],
        '75%': [45, 5],
        'max': [50]
      },
      tooltips: true,
      format: {
        to: function (value) {
          if (value == 50) {
            return Number.parseFloat(value).toFixed(0) + ' € et +';
          }

          return Number.parseFloat(value).toFixed(0) + ' €';
        },
        from: function (value) {
          return Number(value.replace(' et +', '').replace(' €', ''));
        }
      }
    });

    const $button = $('#button-price');

    // Display button to update facets
    range.noUiSlider.on('change.one', function() {
      const button = range.nextElementSibling;
      button.classList.add('visible');

      const href = $button.attr('href');
      const searchParams = new URLSearchParams(href.substring(href.indexOf('?') + 1, href.length));

      const url = new URL(document.location);
      searchParams.forEach(function (value, key) {
        url.searchParams.set(key, value);
      });

      const values = range.noUiSlider.get();
      let min = values[0].replace(' et +', '');
      url.searchParams.set('min', min.substring(0, min.length - 2));

      let max = values[1].replace(' et +', '');
      url.searchParams.set('max', max.substring(0, max.length - 2));

      $button.attr('href', url.toString());
    });
  }
}