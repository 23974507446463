// =============
// Search Facets
// =============

import $ from 'jquery';
import reinitWishlist from '../../scripts/main/wishlist/wishlist'
import resizeTipsHeight from '../../components/jeunesse/tips-filters/tips-filters'

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    $(document).on('click', '.SearchResults-show .Button, .ShowMore .Button', function () {

        let $button = $(this);
        let $container = $button.parent().parent();
        let $progress = $container.find('.progress');
        let $progressBar = $container.find('.progressBar');
        let $total = $container.find('.total');
        let url = $container.data('next-url');

        let DATA_FIGURE_PRESENTED = $('[data-figure-presented]');
        let DATA_ADVICE_PRESENTED = $('[data-advice-presented]');

        if (DATA_FIGURE_PRESENTED.attr('data-figure-presented')) {
            url += '&figures=' + DATA_FIGURE_PRESENTED.attr('data-figure-presented')
        }

        if (DATA_ADVICE_PRESENTED.attr('data-advice-presented')) {
            url += '&advices=' + DATA_ADVICE_PRESENTED.attr('data-advice-presented')
        }

        $.ajax(url)
            .done(function (response) {
                $container.before(response.html);
                $progress.text(response['progress']);
                $total.text(response['number']);
                $progressBar.css('--progress', (parseInt(response['progress']) * 100 / parseInt(response['number'])) + '%');

                DATA_FIGURE_PRESENTED.attr('data-figure-presented', response['figureAlreadyPresented'])
                DATA_ADVICE_PRESENTED.attr('data-advice-presented', response['adviceAlreadyPresented'])
                if ($('.Tips').length > 0) {
                    resizeTipsHeight();
                }

                reinitWishlist();
                if (response['next-url']) {
                    $container.data('next-url', response['next-url']);

                } else {
                    $button.hide();
                }
            });
    });
}


