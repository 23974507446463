// =======================
// Search Facets Event Locator
// =======================

import $ from "jquery";
import Awesomplete from "awesomplete";

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function search(lat, lng) {
    var search = document.location.search;
    const searchParams = new URLSearchParams(search.substring(search.indexOf('?') + 1, search.length));

    const url = new URL(document.location);
    searchParams.forEach(function (value, key) {
        url.searchParams.set(key, value);
    });

    url.searchParams.set('lat', lat);
    url.searchParams.set('lng', lng);

    document.location.search = url.searchParams.toString();
}

function init() {

    let $input = $('#SearchFacetsEventLocator');
    if (!$input.length) {
        return;
    }

    if ("geolocation" in navigator) {
        $('.SearchFacets-geolocationButton').click(function() {
            navigator.geolocation.getCurrentPosition(function(position) {
                search(position.coords.latitude, position.coords.longitude);
            });
        });
    }

    var xhr = null;
    var timeout = null;
    var comboplete = null;
    var text = null;

    $input.keyup(function (event) {
        var input = this;
        let _text = $(input).val().trim();

        if (_text.length <= 2 || _text === text) {
            return;
        }

        text = _text;

        if (timeout) {
            clearTimeout(timeout);
            timeout = false;
        }

        if (xhr) {
            xhr.abort();
            xhr = false;
        }

        timeout = setTimeout(function(){
            let params = {
                'format': 'json',
                'accept-language': 'fr',
                'addressdetails': 1,
                'countrycodes': 'fr,be,ch,re,gp,mq,gy,yt,pf,pm,bl,mf,nc,wf,cp',
                // https://boundingbox.klokantech.com/
                'viewbox': '-1.2579160364,43.2256825785,5.7312452372,49.3378655708',
                'city': text
            };

            // https://nominatim.org/release-docs/develop/api/Search/
            xhr = $.ajax({url: 'https://nominatim.openstreetmap.org/search', data: params, cache: true})
                .done(function (location) {
                    let list = [];

                    location.sort(function (a, b) {
                        var countryA = a.hasOwnProperty('address') && a.address.hasOwnProperty('country_code') ? a.address.country_code : null;
                        var countryB = b.hasOwnProperty('address') && b.address.hasOwnProperty('country_code') ? b.address.country_code : null;

                        if (countryA == 'fr' && countryB != 'fr') {
                            return -1;
                        } else if (countryA != 'fr' && countryB == 'fr') {
                            return 1;
                        }

                        return 0;
                    });

                    let cities = [];
                    for(var i in location) {
                        if (!cities.includes(location[i].display_name)) {
                            list.push({
                                label: location[i].display_name,
                                value: {lat: location[i].lat, lng: location[i].lon },
                            });

                            cities.push(location[i].display_name);
                        }
                    }

                    if (comboplete) {
                        comboplete.destroy();
                    }

                    comboplete = new Awesomplete(input, {
                        list: list,
                        sort: false,
                        replace: function (selection) {
                            this.input.value = selection.label;
                        },
                        filter: function (text, input) {
                            return true;
                        }
                    });

                    Awesomplete.$('#SearchFacetsEventLocator').addEventListener("awesomplete-selectcomplete", function(event) {
                        event.stopPropagation();

                        text = event.text.label;
                        var data = event.text.value;
                        search(data.lat, data.lng);

                        return false;
                    });

                    comboplete.evaluate();
                    input.focus();
                });
        }, 500);

    });
}

