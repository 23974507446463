// ========
// Book Buy
// ========

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function updateBlock(container, data) {
    var bookShops = data.bookshops
    var price = data.price
    var label = data.label
    if (bookShops.length > 0) {
        let innerHtml = '';

        Array.prototype.forEach.call(bookShops, function (el, i) {
            innerHtml += "<li><a href=\"" + el.url + "\" title=\"" + el.name + "\" target=\"_blank\">" + el.name + "</a></li>";
        });

        container.querySelector('ul').innerHTML = innerHtml;

        // Set price
        var priceElement = container
            .parentElement
            .parentElement
            .querySelector('span[data-price]');

        if (priceElement) {
            priceElement.innerHTML = price + ' €';

            // Set title
            container
                .parentElement
                .parentElement
                .querySelector('span[data-price-title]')
                .innerHTML = label;
        }

        return true;
    }

    container
        .parentElement
        .parentElement
        .style
        .display = 'none';

    return false;

}

function init() {
    let bookshopsToShow = false;

    const bookbuyContainers = document.querySelectorAll('.Book-buy');
    if (bookbuyContainers.length === 0) {
        return;
    }

    Array.prototype.forEach.call(bookbuyContainers, function (bookbuyContainer, i) {
        let bookBuyUrl = bookbuyContainer.getAttribute('data-bookbuy-url');

        const printBookLibrariesContainer = bookbuyContainer.querySelector('#PrintBook-libraries');
        const digitalBookLibrariesContainer = bookbuyContainer.querySelector('#DigitalBook-libraries');

        let request = new XMLHttpRequest();
        request.open('GET', bookBuyUrl, true);
        request.onload = function() {
            if (this.status >= 200 && this.status < 400) {
                let data = JSON.parse(this.response);
                if (printBookLibrariesContainer && data.physical) {
                    bookshopsToShow = updateBlock(printBookLibrariesContainer, data.physical);
                }
                if (digitalBookLibrariesContainer && data.digital) {
                    bookshopsToShow = updateBlock(digitalBookLibrariesContainer, data.digital) || bookshopsToShow;
                }

                if (bookshopsToShow) {
                    bookbuyContainer.style.display = 'block';
                }
            }
        };

        request.onerror = function() {
            bookbuyContainer.style.display = 'none';
        };

        request.send();

    });

}


