(() => {
    let $body = $('body');
    function generatePdf(querySelector,filename = null){
        const elementToRender = document.querySelector(querySelector);
        $body.addClass('print')
        displayLoader();
        let opt = {
            margin: [10,2,2,2],
            image : {
                type: 'jpeg',
                quality: 0.7
            },
            filename: string_to_slug(filename ? filename : document.title),
            html2canvas: {
                scale: 2
            },
            pagebreak: {
                mode: ['avoid-all']
            },
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait'
            }
        }

        html2pdf()
            .from(elementToRender)
            .set(opt)
            .toPdf()
            .get('pdf')
            .then((pdf) => {
                var totalPages = pdf.internal.getNumberOfPages();
                for (i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(8);
                    pdf.text(8, 5, getCurrentDateFormatted());
                }
            })
            .save()
            .then(() => {
                $body.removeClass('print')
                hideLoader()
            })
    }

    // Expose function
    window.generatePdf = generatePdf

    function displayLoader(){
        if(!$body.find("#loader-screen").length){
            $body.append('<div id="loader-screen"><span class="loader"></span></div>')
        }
    }

    function hideLoader(){
        if($body.find("#loader-screen").length) {
            $('#loader-screen').remove();
        }
    }

    function string_to_slug (str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to   = "aaaaeeeeiiiioooouuuunc------";
        for (var i=0, l=from.length ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }

    function getCurrentDateFormatted() {
        const now = new Date();
        const day = String(now.getUTCDate()).padStart(2, '0');
        const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = now.getUTCFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    }
})()
