// ===================
// Read More component
// ===================

import $ from 'jquery';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {

  const $main = $('[data-visitors-count]');

  if (typeof $main != 'undefined' && $main.length > 0) {
    let url = Routing.generate('add_visitors_count', {'locationId': $main.data('location-id')});

    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.send();
  }

  // const MOST_VIEWED_CONTAINER = $('[data-replace-mostviewed]');
  //
  // if (typeof MOST_VIEWED_CONTAINER !== 'undefined' && MOST_VIEWED_CONTAINER.length > 0) {
  //     let URL = Routing.generate('get_visitors_most_viewed');
  //
  //     var XHR = new XMLHttpRequest();
  //     XHR.open("POST", URL, true);
  //     XHR.onload = function() {
  //         if (this.status >= 200 && this.status < 400) {
  //             let DATA = this.response;
  //             MOST_VIEWED_CONTAINER.replaceWith(DATA)
  //         }
  //     };
  //     XHR.send();
  // }
}
