// =================
// Homepage Networks
// =================

import Instafeed from "instafeed.js";

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {

    let homepageNetworks = document.querySelector('.HomepageNetworks');
    if (homepageNetworks && homepageNetworks.length !== 0) {
        // set card height before calling Facebook's plugin
        let facebookCard = homepageNetworks.querySelector('.HomepageNetworks-listItem--facebook .fb-page'),
            youtubeCard = homepageNetworks.querySelector('.HomepageNetworks-listItem--youtube');
        if(facebookCard) {
        facebookCard.setAttribute('data-height', youtubeCard.offsetHeight);
        }
        // for Facebook (which badly didn't wrapped this into an ES6 module :-( !)
        var scriptTag = document.createElement('script'), // create a script tag
            firstScriptTag = document.getElementsByTagName('script')[0]; // find the first script tag in the document
        scriptTag.setAttribute('data-src',"https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v7.0"); // facebook SDK javascript
        scriptTag.setAttribute('data-name',"facebook"); // facebook SDK javascript
        scriptTag.setAttribute('data-type',"text/javascript"); // facebook SDK javascript
        scriptTag.setAttribute('type',"text/plain"); // facebook SDK javascript
        firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag); // append the facebook script to the DOM

        // for Instagram
        let instaFeedElement = document.querySelector('[data-instagram-access-token]');
        if (instaFeedElement) {
            const instagramAccessToken = instaFeedElement.getAttribute('data-instagram-access-token');
            if (instagramAccessToken.length !== 0) {
                instaFeed(instagramAccessToken, instaFeedElement)
            }
        }
    }
}

function instaFeed(accessToken, instaFeedElement) {
    const feed = new Instafeed({
        accessToken: accessToken,
        limit: 1,
        target: instaFeedElement,
        template: '<a href="{{link}}" target="_blank"><img title="{{caption}}" src="{{image}}" alt="{{caption}}" /></a>',
    })

    feed.run()
}
