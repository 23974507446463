import $ from 'jquery';
import Foundation from '../foundation'

const attribute = 'data-wishList-item'

const template = function(type, content) {
    return `
        <div class="reveal MessageModal ${type}">
          ${content}          
          <button class="close-button" data-close aria-label="Close reveal" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    `
}

const wishlist = {
    data: [],
    max_books_per_page: 10,
    pagination_increment: 10,
    url: function() {
        return Routing.generate('wishlist_full', {'h': window.localStorage.getItem('wishlist')})
    },
    load: function() {
        if (window.localStorage.length > 0) {
            const storedList = window.localStorage.getItem('wishlist')
            if (storedList) {
                this.data = JSON.parse(atob(storedList))
            }
        } else {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if(urlParams.has('h')) {
                const storedList = urlParams.get('h');
                window.localStorage.setItem('wishlist', storedList)
                this.data = JSON.parse(atob(storedList))
            }
        }
    },
    commit: function() {
        if (this.data.length === 0) {
            window.localStorage.removeItem('wishlist')
        } else {
            this.data
            window.localStorage.setItem('wishlist', btoa(JSON.stringify(this.data)))
        }
    },
    has: function(item) {
        item = item.toString()
        return this.data.includes(item)
    },
    add: function(item) {
        item = item.toString()
        if (this.data.includes(item)) {
            return
        }
        this.data.push(item)
        this.commit()
    },
    remove: function(item) {
        item = item.toString()
        if (!this.data.includes(item)) {
            return
        }

        this.data.splice(this.data.indexOf(item), 1)
        this.commit()
    }
}


const init = function() {
    wishlist.load()
    wishlist.max_books_per_page = $('[data-initial-book-count]').data('initial-book-count')
    wishlist.pagination_increment = $('[data-pagination-increment]').data('pagination-increment')
    if (typeof renderPdf !== 'undefined' && renderPdf === 1) {
        $('ul.BooksList li').removeClass('Hidden')
    } else {
        $('ul.BooksList li').slice(0, wishlist.max_books_per_page).removeClass('Hidden')
        $('.BooksList-button button').click(() => {
            $('ul.BooksList li.Hidden').slice(0, wishlist.pagination_increment).removeClass('Hidden')
            if($('ul.BooksList li.Hidden').length < 1) {
                $('.BooksList-button button').hide();
            }
        })
    }
    $('#link-ma-liste').click(() => {
        window.location.replace(wishlist.url())
    })

    $('[data-link-liste]').click(() => {
        window.location.replace(wishlist.url())
    })

    $('[data-delete-button]').click((e) => {
         removeFromWishList($(e.currentTarget))
    })



    $(`[${attribute}]`).each( function( index, item ) {
        if (wishlist.has(item.getAttribute(attribute))) {
            item.classList.add('wishlisted')
            item.setAttribute('title', 'Retirer de ma liste')
        }

        if(!$(item).hasClass('loaded')) {
            $(item).addClass('loaded')


        $(item).on('click', (event) => {
            event.stopPropagation()
            const bookEAN = event.target.getAttribute(attribute)
            let type
            let message
            if (wishlist.has(bookEAN)) {
                wishlist.remove(bookEAN)
                event.target.classList.remove('wishlisted')
                item.setAttribute('title', 'Ajouter à ma liste')
            } else {
                addToWishList(bookEAN)
                event.target.classList.add('wishlisted')
                item.setAttribute('title', 'Retirer de ma liste')
                type = 'Success'
                message = 'Ce livre a bien été ajouté à votre liste.'
                const link = `<a href="${wishlist.url()}">Voir ma liste</a>`
                const markup = template(type, `<p>${message}</p>${link}`)
                const modal = new Foundation.Reveal($(markup))
                modal.open()
            }

        })
        }
    })
    $('[data-pdf-wishlist]').click(() => {
        window.open(Routing.generate('wishlist_pdf', {'h': window.localStorage.getItem('wishlist')}))
    })
    $('[data-email-wishlist]').on('click', () => {
        fetch(Routing.generate('wishlist_email', {'h': window.localStorage.getItem('wishlist')}))
            .then(response => {
                return response.text()
            })
            .then(data => {
                const markup = template('Success email-wishlist-modal', data)
                const modal = new Foundation.Reveal($(markup), {
                    overlay: true,
                })
                $(document).on('open.zf.reveal', '.email-wishlist-modal', () => {
                    $('.winniethepoe').hide();
                    $('button#btn-submit-email-form').off('click').click(e => {
                        e.preventDefault()
                        $('.form-error').remove()
                        const form = document.querySelector('.email-wishlist-modal form')
                        fetch(form.getAttribute('action'), {
                            method: form.getAttribute('method'),
                            body: new URLSearchParams(new FormData(form))
                        })
                        .then(response => {
                            return response.json()
                        })
                        .then(data => {
                            if (data.status == 'ok' || data.status == 'error') {
                                if (data.status == 'ok') form.remove()
                                $('.email-wishlist-modal').append($('<div>').html(data.message))
                            } else {
                                data.errors.forEach((error) => {
                                    const selector = `[id='wishlist_email_${error.field}']`
                                    $('<p>').text(error.message).addClass('form-error').insertBefore(selector)
                                })
                            }
                        })
                    })
                })
                $(document).on('closed.zf.reveal', '.email-wishlist-modal', () => {
                    const elt = document.querySelector('.email-wishlist-modal')
                    if (elt) elt.remove();
                })
                modal.open()
            })
        ;
    })
}

const reinitWishlist = function () {
    init();
}

const addToWishList = function(item) {
    wishlist.add(item)
}

const removeFromWishList = function(target) {
    wishlist.remove(target.data('delete-button'))
    window.location.replace(wishlist.url())
}

export { init as default, removeFromWishList, reinitWishlist }
