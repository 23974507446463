// ============
// Books slider
// ============

import Swiper from 'swiper';
import imagesLoaded from 'imagesloaded';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const sliders = document.querySelectorAll('.BooksSlider');

  for (const slider of sliders) {



    const sliderContainer = slider.querySelector('.Slider-container');
    const slides = slider.querySelectorAll('.Slider-slide');

    for (const slide of slides) {
      const cover = slide.querySelector('.BookItem-cover img:first-child');
      imagesLoaded(cover, function() {
        const coverWidth = cover.width;

        slide.style.maxWidth = coverWidth + 'px';


        const newSlider = new Swiper(sliderContainer, {
          slidesPerView: 'auto',
          spaceBetween: slider.classList.contains('Bd') ? 0 : 40,
          watchOverflow: true,
          navigation: {
            prevEl: slider.querySelector('.Slider-buttonPrev'),
            nextEl: slider.querySelector('.Slider-buttonNext')
          },
          breakpoints: {
            1400: {
              spaceBetween: slider.classList.contains('Bd') ? 0 : 56
            }
          }
        })
      })
    }
  }
}

