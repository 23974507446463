// =========
// Accordion
// =========

import $ from 'jquery';
import { Accordion } from './foundation';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const accordions = $('[data-accordion-items]'),
        accordionOptions = {
          allowAllClosed: true,
          multiExpand: true
        };

  accordions.each(function() {
    const accordion = $(this),
          accordionInit = new Accordion(accordion, accordionOptions);
  });
}

