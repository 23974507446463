import $ from 'jquery';

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    $('[data-reveal]').on('open.zf.reveal', function (event) {
        var $this = $(this);
        var edenWrapContainer = $this.find('.eden-wrap-container');
        if (edenWrapContainer.length === 0) {
            return;
        }

        if (edenWrapContainer.find('iframe').length > 0) {
            return;
        }

        var iframe = document.createElement('iframe');
        iframe.src = edenWrapContainer.data('url');

        edenWrapContainer.html(iframe.outerHTML);
    });
}