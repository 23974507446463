import $ from 'jquery';
import readmore from "readmore-js";

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {

    let moreLink = '<div class="Truncate-expandButton Truncate-button"><button class="Button Button--primary Button--green"><span>Lire la suite</span></button></div>',
        lessLink = '<div class="Truncate-unexpandButton Truncate-button"><button class="Button Button--primary Button--green"><span>Réduire</span></button></div>';


    // TRUNCATE #1
    // Book resume text truncate

    const $truncate1 = $('.Truncate--bookResume');

    if (typeof $truncate1 != 'undefined' && $truncate1.length > 0) {
        const resume = $truncate1;
        const options = {
            speed: 250,
            collapsedHeight: 225,
            moreLink: moreLink,
            lessLink: lessLink
        };

        let totalHeight = 0;

        resume.children().each(function () {
            totalHeight += $(this).outerHeight();
        });

        if (totalHeight >= 300) {
            resume.readmore(options);
        }
    }


    // TRUNCATE #2
    // Hero text block truncate

    const $truncate2 = $('.Truncate--hero');

    if (typeof $truncate2 != 'undefined' && $truncate2.length > 0) {
        const resume = $truncate2;
        const paddingTop = parseInt(resume.prevObject.find('.Hero-container').css('padding-top'));
        const paddingBottom = parseInt(resume.prevObject.find('.Hero-container').css('padding-bottom'));
        const marginBottom = 20;
        const limit = 500;
        const buttonHeight = 40;
        let titleHeight = 0;
        let linksHeight = 0;
        let networksHeight = 0;

        if ($('.Hero-heading').length) {
            titleHeight += resume.prevObject.find('.Hero-heading').outerHeight(true);
        }
        if ($('.Hero-links').length) {
            linksHeight += resume.prevObject.find('.Hero-links').outerHeight(true);
        }
        if ($('.SocialNetworkss').length) {
            networksHeight += resume.prevObject.find('.SocialNetworks').outerHeight(true);
        }

        const collapsedHeight = limit - (paddingTop + titleHeight + buttonHeight + marginBottom + linksHeight + networksHeight + paddingBottom);

        const options = {
            speed: 250,
            collapsedHeight: Math.floor(collapsedHeight),
            moreLink: moreLink,
            lessLink: lessLink
        };

        let totalHeight = 0;
        totalHeight += $('.Truncate-container').outerHeight();

        if (totalHeight >= limit) {
            resume.readmore(options);
        }
    }


    // TRUNCATE #3
    // Category hero text block truncate

    const $truncate3 = $('.CategoryHero .Truncate--hero');
    if (typeof $truncate3 != 'undefined' && $truncate3.length > 0) {
        const resume = $truncate3;
        const titleHeight = resume.prevObject.find('.Hero-heading').outerHeight(true);
        const limit = 210;
        const buttonHeight = 40;
        const collapsedHeight = limit - (titleHeight + buttonHeight);
        const options = {
            speed: 250,
            collapsedHeight: collapsedHeight,
            moreLink: moreLink,
            lessLink: lessLink,
            afterToggle: function (trigger, element, expanded) {
                if (expanded) {
                    element.css('height', 'auto !important');
                }
            }
        };

        let totalHeight = 0;
        totalHeight += $('.Truncate-container').outerHeight();

        if (totalHeight >= limit) {
            resume.readmore(options);

            if (window.matchMedia('min-width: 900px').matches) {
                resume.parent('.Hero-text').css(
                    'max-height', (limit + buttonHeight) - titleHeight + 'px'
                );
            }
        }
    }


    // TRUNCATE #4
    // Book press container text truncate

    const $truncate4 = $('.Truncate--press');

    if (typeof $truncate4 != 'undefined' && $truncate4.length > 0) {
        const resume = $truncate4;
        const options = {
            speed: 250,
            collapsedHeight: 160,
            moreLink: moreLink,
            lessLink: lessLink,
            afterToggle: function (trigger, element, expanded) {
                if (expanded) {
                    element.css('height', 'auto !important');
                }
            }
        };

        let totalHeight = 0;
        totalHeight += $('.Truncate-container').outerHeight();

        if (totalHeight >= 180) {
            resume.readmore(options);
        }
    }


    // REFACTOR TRUNCATE

    /**
     * @type {Object}
     */
    let TRUNCATE_CONTENT = $('[data-truncate-content]');


    if (TRUNCATE_CONTENT.length < 1) return;

    TRUNCATE_CONTENT.each((index, element) => {
            /**
             * @type {Object}
             */
            let ELEMENT = $(element);
            let OPEN_BUTTON_TRIGGER = ELEMENT.siblings('[data-open-trigger]').eq(0);

            const options = {
                speed: 250,
                collapsedHeight: ELEMENT.data('truncate-content'),
                moreLink: "",
                lessLink: "",
                beforeToggle: function (trigger, element, expanded) {
                    if (expanded) { // The "Close" link was clicked
                        OPEN_BUTTON_TRIGGER.removeClass('Truncate-unexpandButton')
                        OPEN_BUTTON_TRIGGER.find('span').text('Lire la suite')
                    } else {
                        $('html, body').animate({scrollTop: ELEMENT.offset().top - 100 },500);
                        OPEN_BUTTON_TRIGGER.addClass('Truncate-unexpandButton')
                        OPEN_BUTTON_TRIGGER.find('span').text('Réduire')
                    }
                }
            };

            if(ELEMENT.innerHeight() < ELEMENT.data('truncate-content') + 100) {
                OPEN_BUTTON_TRIGGER.css('display', 'none');
            } else {
                ELEMENT.readmore(options);
            }

            OPEN_BUTTON_TRIGGER.on('click', function (e) {
                ELEMENT.readmore('toggle');
            });
        }
    )
}
