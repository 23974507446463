// ===========
// Back to Top
// ===========

import $ from 'jquery';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  $(".Footer-backtoTop").click(function () {
    $("html,body").animate({scrollTop: $("body").offset().top}, "1000");
    return false
  })
}