// ===================
// Tips Filters script
// ===================

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  resizeTipsHeight();
}


function resizeTipsHeight() {
    const tipsList = document.querySelectorAll('.TipsFilters-list');

    for (const tipsItem of tipsList) {
        const tipsImage = tipsItem.querySelector('.Tips .NewsItem-image');
        const tipsText = tipsItem.querySelectorAll('.Tips .NewsItem-text');

        for (const text of tipsText) {
            text.style.height = 'calc(100% - ' + tipsImage.offsetHeight + 'px)';
        }

        window.addEventListener('resize', function() {
            for (const text of tipsText) {
                text.style.height = 'calc(100% - ' + tipsImage.offsetHeight + 'px)';
            }
        });
    }
}

export { init as default, resizeTipsHeight };
