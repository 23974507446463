// ===============
// Book attachment
// ===============

import Swiper from 'swiper';

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    const attachments = document.querySelectorAll('.BookAttachment');

    for (const attachment of attachments) {
        const attachmentContainer = attachment.querySelector('.BookAttachment-container');
        new Swiper(attachmentContainer, {
            // centerInsufficientSlides: true,
            autoHeight: true,
            // slidesPerView: '2',
            slidesPerView: 'auto',
            spaceBetween: 25,
            watchOverflow: true,
            pagination: {
                el: attachment.querySelector('.Slider-bullets'),
                type: 'bullets',
                bulletElement: 'li',
                bulletClass: 'Slider-bullet',
                bulletActiveClass: 'Slider-bullet--active',
                clickable: true
            },
            navigation: {
                prevEl: attachment.querySelector('.BookAttachment-buttonPrev'),
                nextEl: attachment.querySelector('.BookAttachment-buttonNext')
            }
        })
    }
}

