// ===================
// Read More component
// ===================

import $ from 'jquery';
import readmore from "readmore-js";

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const truncate1 = $('.Hero-textBlock');
}