import $ from 'jquery';

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    /**
     *
     * @type {Object}
     */
    const VIDEO_CONTAINER = $(".video-container");

    if(VIDEO_CONTAINER.length < 1 ) {
        return;
    }


    VIDEO_CONTAINER.each(function (index, value) {
        var RICHTEXT = $(value)
        var VIDEO = RICHTEXT.find('.video-embed');


        if(VIDEO.length === 1 && RICHTEXT.siblings(".Title").length > 0) {
            var TITLE = RICHTEXT.siblings(".Title").children('span');
            $(TITLE).text('Vidéo')
        }

        if (VIDEO.length > 1) {
            RICHTEXT.addClass("multipleVideos")
            // RICHTEXT.parent().parent().addClass('overrideBackground')
        }

        if($(window).width() > 700 ) {
            VIDEO.eq(0).addClass('firstEllement').wrap('<div class="videoContainer-left"></div>')
            VIDEO.eq(0).find('.video-text').removeClass('video-text').addClass('RichText').appendTo(RICHTEXT);
            VIDEO.not('.firstEllement').wrapAll('<div class="videoContainer-right"></div>')
        }
    })
}
