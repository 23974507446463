import $ from 'jquery';
let klaro = require('./klaro.js');

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    $(document).on('click', '.klaro-open', function (e) {
        if ($(this).is('a[href="#"]')) {
            e.preventDefault();
        }

        klaro.show(undefined, true);
    })
}
