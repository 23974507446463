// ===============
// Select Dropdown
// ===============

import $ from 'jquery';
import { Dropdown } from './foundation';

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}

function init() {
  const dropdowns = $('[data-select-dropdown]'),
        dropdownOptions = {
          closeOnClick: true
        };

  dropdowns.each(function() {
    const dropdown = $(this),
          dropdownInit = new Dropdown(dropdown, dropdownOptions);

    this.foundationDropdown = dropdownInit;

    dropdown.on('show.zf.dropdown', function() {
      dropdown.children().fadeOut().slideDown(400);
    });
    dropdown.on('hide.zf.dropdown', function() {
      dropdown.children().fadeIn().slideUp(300);
    });
  });
}
