// ==============
// Filters slider
// ==============

import Swiper from 'swiper';

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
} else {
    init();
}

function init() {
    const filters = document.querySelectorAll('.FiltersSlider');

    for (const filter of filters) {
        const filtersContainer = filter.querySelector('.FiltersSlider-container');
        const newFiltersSet = new Swiper(filtersContainer, {
            slidesPerView: 'auto',
            spaceBetween: 20,
            watchOverflow: true,
            navigation: {
                prevEl: filter.querySelector('.Slider-buttonPrev'),
                nextEl: filter.querySelector('.Slider-buttonNext')
            }
        })
    }

    const slidersFilters = document.querySelectorAll('.FiltersSlider > ul > .Filters-filter');
    if(slidersFilters.length > 1) {
        slidersFilters[0].closest('.FiltersSlider').classList.add('multipleRows')
    }
}

