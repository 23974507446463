// ------
// Styles
// ------

// Libraries
import "awesomplete/awesomplete.css"
import "normalize.css/normalize.css";
import "nouislider/distribute/nouislider.min.css";
import "swiper/css/swiper.min.css";

// -------
// Scripts
// -------

// Libraries
import $ from "jquery";
import Spotlight from "spotlight.js";
import "what-input";
import "motion-ui";
import "./scripts/main/foundation";
import "./scripts/main/search-result-loader";
import "./scripts/main/external-url";
import "./scripts/main/eden-bridge";
import "./scripts/main/videos-richetxt-formateur";


// // Made by back-end
import "./scripts/main/klaro";
import "./scripts/main/klaro-custom";
import "./scripts/main/klaro-observer";
import "./scripts/main/ez-table-override";
import "./scripts/main/visitors-count";

// actions
import WishList from "./scripts/main/wishlist/wishlist";

// Global
import "./scripts/main/accessibility/targetblank";
import "./scripts/main/backtotop";
import "./scripts/main/read-more";
import "./scripts/main/truncate";
import "../src/components/main/pdf/pdf";

// Components
import "./scripts/main/accordion";
import "./scripts/main/ages-tooltip";
import "./scripts/main/book-attachment";
import "./scripts/main/book-buy";
import "./scripts/main/book-flip";
import "./scripts/main/books-list";
import "./scripts/main/books-slider";
import "./scripts/main/filters-slider";
import "./scripts/main/footer-accordion";
import "./scripts/main/homepage-networks";
import "./scripts/main/menu-dropdown";
import "./scripts/main/news-slider";
import "./scripts/main/range";
import "./scripts/main/select-dropdown";
import "./scripts/main/tips-responsive-slider";
import "./scripts/main/search-facets";
import "./scripts/main/search-facets-thematics";
import "./scripts/main/search-facets-event-locator";

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    $(document).foundation()
    WishList()
  });
} else {
  $(document).foundation()
  WishList()
}
